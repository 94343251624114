var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"banner"},[_c('el-carousel',{attrs:{"height":"500px"}},_vm._l((3),function(item){return _c('el-carousel-item',{key:item},[_c('img',{attrs:{"src":require("../assets//assets/image/banner.png")}})])}),1)],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"carType"},[_c('el-carousel',{attrs:{"interval":4000,"type":"card","height":"200px","indicator-position":"none"}},[_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/assets/image/cartype.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/assets/image/cartype1.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/assets/image/cartype2.png"),"alt":""}})])],1)],1),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"welcome"},[_vm._v("欢迎光临e点有车！")]),_c('div',{staticClass:"login"},[_c('span',{staticClass:"login-button"},[_vm._v("登录")]),_c('span',{staticClass:"login-button"},[_vm._v("注册")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navTitle"},[_c('span',{staticClass:"titleActive"},[_c('span',{staticClass:"characters"},[_vm._v("首页")]),_c('span',[_vm._v("HOME")])]),_c('span',{staticClass:"title"},[_c('span',{staticClass:"characters"},[_vm._v("关于我们")]),_c('span',[_vm._v("ABOUT")])]),_c('span',{staticClass:"title"},[_c('span',{staticClass:"characters"},[_vm._v("产品中心")]),_c('span',[_vm._v("PRODUCT")])]),_c('span',{staticClass:"title"},[_c('span',{staticClass:"characters"},[_vm._v("新闻中心")]),_c('span',[_vm._v("NEWS")])]),_c('span',{staticClass:"title"},[_c('span',{staticClass:"characters"},[_vm._v("零件销售")]),_c('span',[_vm._v("PARTS")])]),_c('span',{staticClass:"title"},[_c('span',{staticClass:"characters"},[_vm._v("联系我们")]),_c('span',[_vm._v("CONTACT")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('span',{staticClass:"chinese"},[_vm._v("关于我们")]),_c('img',{attrs:{"src":require("../assets/assets/image/tag.png"),"alt":""}}),_c('span',{staticClass:"english"},[_vm._v("About us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterprise"},[_c('img',{attrs:{"src":require("../assets/assets/image/enterprise.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduction"},[_c('div',{staticClass:"brandHistoryactive"},[_c('img',{attrs:{"src":require("../assets/assets/image/iconactive2.png"),"alt":""}}),_c('span',{staticClass:"name"},[_vm._v("品牌历程")]),_c('span',{staticClass:"des"},[_vm._v("e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆")])]),_c('div',{staticClass:"brandHistory"},[_c('img',{attrs:{"src":require("../assets/assets/image/icon3.png"),"alt":""}}),_c('span',{staticClass:"name"},[_vm._v("品牌历程")]),_c('span',{staticClass:"des"},[_vm._v("e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆")])]),_c('div',{staticClass:"brandHistory"},[_c('img',{attrs:{"src":require("../assets/assets/image/icon2.png"),"alt":""}}),_c('span',{staticClass:"name"},[_vm._v("品牌历程")]),_c('span',{staticClass:"des"},[_vm._v("e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆")])]),_c('div',{staticClass:"brandHistory"},[_c('img',{attrs:{"src":require("../assets/assets/image/icon3.png"),"alt":""}}),_c('span',{staticClass:"name"},[_vm._v("品牌历程")]),_c('span',{staticClass:"des"},[_vm._v("e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serach"},[_c('div',{staticClass:"text"},[_vm._v("查询车辆情况")]),_c('div',{staticClass:"des"},[_vm._v(" 用心倾听，及时回应，以贴心的服务态度，让您尽享非凡礼遇。 如果您有任何疑问或建议，请与平台联系。 ")]),_c('button',[_vm._v("立即查询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('span',{staticClass:"chinese"},[_vm._v("产品展示")]),_c('img',{attrs:{"src":require("../assets/assets/image/tag.png"),"alt":""}}),_c('span',{staticClass:"english"},[_vm._v("Product Show")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('span',{staticClass:"chinese"},[_vm._v("新闻中心")]),_c('img',{attrs:{"src":require("../assets/assets/image/tag.png"),"alt":""}}),_c('span',{staticClass:"english"},[_vm._v("News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guaranteeBox"},[_c('div',{staticClass:"guarantee"},[_c('div',{staticClass:"guaranteeDate"},[_c('span',{staticClass:"day"},[_vm._v("03-08")]),_c('span',{staticClass:"year"},[_vm._v("2023")])]),_c('div',{staticClass:"guaranteeContent"},[_c('span',{staticClass:"title"},[_vm._v("安全高保障")]),_c('span',{staticClass:"des"},[_vm._v("商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。")])])]),_c('div',{staticClass:"guarantee"},[_c('div',{staticClass:"guaranteeDate"},[_c('span',{staticClass:"day"},[_vm._v("03-08")]),_c('span',{staticClass:"year"},[_vm._v("2023")])]),_c('div',{staticClass:"guaranteeContent"},[_c('span',{staticClass:"title"},[_vm._v("安全高保障")]),_c('span',{staticClass:"des"},[_vm._v("商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。")])])]),_c('div',{staticClass:"guarantee"},[_c('div',{staticClass:"guaranteeDate"},[_c('span',{staticClass:"day"},[_vm._v("03-08")]),_c('span',{staticClass:"year"},[_vm._v("2023")])]),_c('div',{staticClass:"guaranteeContent"},[_c('span',{staticClass:"title"},[_vm._v("安全高保障")]),_c('span',{staticClass:"des"},[_vm._v("商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。")])])]),_c('div',{staticClass:"guarantee"},[_c('div',{staticClass:"guaranteeDate"},[_c('span',{staticClass:"day"},[_vm._v("03-08")]),_c('span',{staticClass:"year"},[_vm._v("2023")])]),_c('div',{staticClass:"guaranteeContent"},[_c('span',{staticClass:"title"},[_vm._v("安全高保障")]),_c('span',{staticClass:"des"},[_vm._v("商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。")])])])])
}]

export { render, staticRenderFns }