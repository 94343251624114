<template>
  <div class="wrap">
    <div class="nav">
      <div class="welcome">欢迎光临e点有车！</div>
      <div class="login">
        <span class="login-button">登录</span>
        <span class="login-button">注册</span>
      </div>
    </div>
    <div class="navTitle">
      <span class="titleActive">
        <span class="characters">首页</span>
        <span>HOME</span>
      </span>
      <span class="title">
        <span class="characters">关于我们</span>
        <span>ABOUT</span>
      </span>
      <span class="title">
        <span class="characters">产品中心</span>
        <span>PRODUCT</span>
      </span>
      <span class="title">
        <span class="characters">新闻中心</span>
        <span>NEWS</span>
      </span>
      <span class="title">
        <span class="characters">零件销售</span>
        <span>PARTS</span>
      </span>
      <span class="title">
        <span class="characters">联系我们</span>
        <span>CONTACT</span>
      </span>
    </div>
    <div class="banner">
      <el-carousel height="500px">
        <el-carousel-item v-for="item in 3" :key="item">
          <img src="../assets//assets/image/banner.png" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about">
      <span class="chinese">关于我们</span>
      <img src="../assets/assets/image/tag.png" alt="" />
      <span class="english">About us</span>
    </div>
    <div class="enterprise">
      <img src="../assets/assets/image/enterprise.png" alt="" />
    </div>
    <div class="introduction">
      <div class="brandHistoryactive">
        <img src="../assets/assets/image/iconactive2.png" alt="" />
        <span class="name">品牌历程</span>
        <span class="des"
          >e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆</span
        >
      </div>
      <div class="brandHistory">
        <img src="../assets/assets/image/icon3.png" alt="" />
        <span class="name">品牌历程</span>
        <span class="des"
          >e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆</span
        >
      </div>
      <div class="brandHistory">
        <img src="../assets/assets/image/icon2.png" alt="" />
        <span class="name">品牌历程</span>
        <span class="des"
          >e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆</span
        >
      </div>
      <div class="brandHistory">
        <img src="../assets/assets/image/icon3.png" alt="" />
        <span class="name">品牌历程</span>
        <span class="des"
          >e点有车隶属于易点无忧(北京)网络科技有限责任公司，我们作为行业内最大的网约车运力供应商，可为司机群体从业提供培训、考证、车辆</span
        >
      </div>
    </div>
    <div class="serach">
      <div class="text">查询车辆情况</div>
      <div class="des">
        用心倾听，及时回应，以贴心的服务态度，让您尽享非凡礼遇。
        如果您有任何疑问或建议，请与平台联系。
      </div>
      <button>立即查询</button>
    </div>
    <div class="about">
      <span class="chinese">产品展示</span>
      <img src="../assets/assets/image/tag.png" alt="" />
      <span class="english">Product Show</span>
    </div>
    <div class="carType">
      <el-carousel
        :interval="4000"
        type="card"
        height="200px"
        indicator-position="none"
      >
        <el-carousel-item>
          <img src="../assets/assets/image/cartype.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/assets/image/cartype1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/assets/image/cartype2.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="about">
      <span class="chinese">新闻中心</span>
      <img src="../assets/assets/image/tag.png" alt="" />
      <span class="english">News</span>
    </div>
    <div class="guaranteeBox">
      <div class="guarantee">
        <div class="guaranteeDate">
          <span class="day">03-08</span>
          <span class="year">2023</span>
        </div>
        <div class="guaranteeContent">
          <span class="title">安全高保障</span>
          <span class="des"
            >商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。</span
          >
        </div>
      </div>
      <div class="guarantee">
        <div class="guaranteeDate">
          <span class="day">03-08</span>
          <span class="year">2023</span>
        </div>
        <div class="guaranteeContent">
          <span class="title">安全高保障</span>
          <span class="des"
            >商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。</span
          >
        </div>
      </div>
      <div class="guarantee">
        <div class="guaranteeDate">
          <span class="day">03-08</span>
          <span class="year">2023</span>
        </div>
        <div class="guaranteeContent">
          <span class="title">安全高保障</span>
          <span class="des"
            >商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。</span
          >
        </div>
      </div>
      <div class="guarantee">
        <div class="guaranteeDate">
          <span class="day">03-08</span>
          <span class="year">2023</span>
        </div>
        <div class="guaranteeContent">
          <span class="title">安全高保障</span>
          <span class="des"
            >商用车在设计和技术特性上用于运送人员和货物，并且可以牵引挂车，但乘用车不包括在内。</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.wrap {
  display: flex;
  flex-direction: column;
  .nav {
    height: 40px;
    background: #000000;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .welcome {
      font-size: 15px;
    }
    .login {
      line-height: 20px;
      .login-button {
        width: 40px;
        height: 22px;
        border: 1px solid #ffffff;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 13px;
        display: inline-block;
        text-align: center;
      }
    }
  }
  .enterprise {
    padding: 20px 100px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .introduction {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    .brandHistory {
      width: 150px;
      border: 1px solid #db001a;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      margin: 0 20px;
      img {
        width: 50px;
        height: 50px;
      }
      .name {
        margin: 10px;
      }
      .des {
        font-size: 12px;
        font-weight: 400;
        color: #5c5c5c;
      }
    }
    .brandHistoryactive {
      width: 150px;
      border: 1px solid #db001a;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      background: red;
      color: #fff;
      margin: 0 20px;
      img {
        width: 50px;
        height: 50px;
      }
      .name {
        margin: 10px;
      }
      .des {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .navTitle {
    display: flex;
    margin-left: 50%;
    .title {
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      text-align: center;
      .characters {
        margin-top: 20px;
      }
    }
    .titleActive {
      width: 80px;
      height: 80px;
      background: #db001a;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      text-align: center;
      color: #fff;
      .characters {
        margin-top: 20px;
      }
    }
  }
  .banner {
    .el-carousel__item {
      color: #475669;
      font-size: 18px;
      // opacity: 0.75;
      // line-height: 300px;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
      }
      background: #000018;
    }
  }
  .about {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    position: relative;
    img {
      width: 150px;
      height: 50px;
    }
    .chinese {
      position: absolute;
      left: 50px;
    }
    .english {
      position: absolute;
      top: 25px;
      left: 50px;
      font-size: 12px;
      color: #616161;
    }
  }
  .serach {
    height: 260px;
    background-image: url("../assets/assets/image/carBackground.png");
    padding: 50px 0 50px 200px;
    background-size: 100%;
    .text {
      width: 244px;
      height: 40px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .des {
      width: 557px;
      height: 56px;
      font-size: 12px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .carType {
    width: 600px;
    margin: 0 auto;
    .el-carousel__item {
      color: #475669;
      font-size: 14px;
      margin: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .guaranteeBox {
    background: #eeeeee;
    display: flex;
    flex-wrap: wrap;
    .guarantee {
      width: 40%;
      display: flex;
      margin: 20px auto;
      .guaranteeDate {
        width: 135px;
        background: #f00;
        display: flex;
        flex-direction: column;
        align-items: center;
        .day {
          font-size: 20px;
          color: #fff;
          margin-top: 10px;
        }
        .year {
          font-size: 12px;
          color: #fff;
        }
      }
      .guaranteeContent {
        display: flex;
        flex-direction: column;
        padding: 10px 0 10px 20px;
        background: #fff;
        .title {
          font-size: 15px;
          color: #1f1f1f;
        }
        .des {
          font-size: 12px;
          color: #1f1f1f;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>